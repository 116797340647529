<template>
    <div class="register-page">
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="个人注册" name="1">
                <div class="content">
                    <el-form v-if="activeTab == 1" ref="registerForm" :model="registerForm" :rules="rules" label-width="0">
                        <el-form-item prop="phone">
                            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <el-button type="primary" :disabled="countdown > 0" @click="getCode">
                                        {{ countdown > 0 ? `${countdown}秒` : '获取验证码' }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" class="register-button" @click="register">个人注册</el-button>
                            <p class="agreement">注册即表示同意 <el-link>《账号服务协议》</el-link> 和 <el-link>《隐私协议》</el-link></p>
                            <p class="wechat-info">微信用户PC端绑定手机号后即可使用手机号登录</p>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="企业注册" name="2">
                <div class="content">
                    <el-form v-if="activeTab == 2" ref="registerForm" :model="registerForm" :rules="rules" label-width="0">
                        <el-form-item prop="organizationType">
                            <el-select style="width: 100%;" v-model="registerForm.organizationType" placeholder="请选择企业类型">
                                <el-option label="企业" value="1"></el-option>
                                <el-option label="机关事业" value="2"></el-option>
                                <el-option label="社团组织或团体" value="3"></el-option>
                                <el-option label="个体工商户" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <el-button
                                        style="border-radius: 0 4px 4px 0;width: 120px;"
                                        :style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '35px' : '40px', }"
                                        :disabled="countdown>0"
                                        @click="getCode">
                                        {{ countdown > 0 ? `${countdown}秒` : '获取验证码' }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" class="register-button" @click="register">机构注册</el-button>
                            <p class="agreement">注册即表示同意 <el-link>《账号服务协议》</el-link> 和 <el-link>《隐私协议》</el-link></p>
                            <p class="wechat-info">微信用户PC端绑定手机号后即可使用手机号登录</p>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            roomId: this.$route.query.roomId,
            activeTab: '1',
            registerForm: {
                phone: '',
                code: '',
                organizationType: '1', // 默认值
                regType:1,
            },
            countdown: 0,
            timer: null,
            rules: {
                phone: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {pattern: /^\d{11}$/, message: '请输入正确的11位手机号', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '验证码不能为空', trigger: 'blur'},
                    {pattern: /^\d{6}$/, message: '请输入正确的6位验证码', trigger: 'blur'}
                ],
                organizationType: [
                    {required: true, message: '请选择机构类别', trigger: 'change'}
                ]
            }
        };
    },
    mounted() {
    },
    methods: {
        handleTabClick() {
            this.registerForm.regType = this.activeTab;
            this.registerForm.phone = "";
            this.registerForm.code = "";
        },

        //获取验证码
        getCode() {
            const that = this;
            if (this.countdown > 0) return;
            this.$refs.registerForm.validateField('phone', (errorMsg) => {
                if (!errorMsg) {
                    this.startCountdown();
                    Vue.prototype.openLoading("请稍后...");
                    this.newApi.sendSms({"mobile":that.registerForm.phone, smsType: 5}).then((ret)=>{
                        that.utils.sus(ret.data);
                    }).catch((err)=>{
                        that.utils.err(err);
                    });
                }
            })
        },

        //倒计时
        startCountdown() {
            // 获取验证码逻辑
            if (this.countdown > 0) return;
            this.countdown = 60;
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },

        //立即注册
        register() {
            var that = this;
            this.$refs.registerForm.validate((valid) => {
                if (valid) {
                    Vue.prototype.openLoading("请稍后...");
                    localStorage.token = "";
                    localStorage.uuid = "";
                    that.newApi.mobileReg(this.registerForm).then((ret)=>{
                        if (ret.isSuccess === 1){
                            that.utils.sus("注册成功");
                            setTimeout(function (){
                                localStorage.token = ret.data.token;
                                localStorage.uuid = ret.data.uuid;
                                that.$store.commit("get_user_info");
                                //记录登录时间
                                that.newApi.updateLoginTime({uuid: ret.data.uuid});
                                // 重置到一个新的URL地址
                                window.location.href = that.utils.getUrlType() + 'joinRoom?state=' + that.roomId;
                            },500)
                        }
                    })
                }
            });
        },
    },
};
</script>

<style scoped>
.register-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100vh;
}

.content {
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.register-button {
    width: 100%;
    background-color: #409EFF;
    border: none;
}

.agreement {
    margin: 20px 0;
    text-align: center;
    font-size: 12px;
    color: #999;
}

.wechat-info {
    text-align: center;
    font-size: 12px;
    color: #999;
    line-height: 0px;
}

/* 响应式设计 */
@media (max-width: 600px) {
    .content {
    }

    .register-button {
        font-size: 14px;
    }

    .agreement, .wechat-info {
        font-size: 10px;
    }
}
</style>
